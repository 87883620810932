import React from 'react'
import { Box, Flex, Text } from 'rebass'
import { Sections } from '../MainMenu'
import { styles } from './drawerStyles'
import { ReactComponent as LogoutIcon } from './logout.svg'
import { ReactComponent as MayaIcon } from './maya.svg'
import { ReactComponent as TodoIcon } from './todo.svg'
import { ReactComponent as WellnessIcon } from './wellness.svg'
import { ReactComponent as AppointmentIcon } from './appointment.svg'
import { ReactComponent as DashboardIcon } from './dashboard.svg'
import { ReactComponent as FindIcon } from './find.svg'
import { useTranslation } from 'react-i18next'

type DrawerProps = {
  collapsed: boolean
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
  setActiveSection: React.Dispatch<React.SetStateAction<Sections>>
  activeSection: string
  onLogout: () => void
}
export const Drawer = ({
  collapsed,
  setCollapsed,
  activeSection,
  setActiveSection,
  onLogout
}: DrawerProps) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{ minWidth: 'unset', zIndex: 1 }}
      onClick={() => setCollapsed(prev => !prev)}
    >
      <Flex as="ul" sx={styles.drawer(collapsed)}>
        <Flex sx={styles.logo}>
          <MayaIcon />
          <Text sx={styles.menuItemLabel(collapsed)}>Maya MD</Text>
        </Flex>

        <Flex sx={styles.menu}>
          {/* <li
            data-selected={activeSection.includes('dashboard')}
            onClick={() => {
              setActiveSection('dashboard')
              setCollapsed(true)
            }}
            className="focusable-main-menu"
          >
            <DashboardIcon />
            <Text sx={styles.menuItemLabel(collapsed)}>Dashboard</Text>
          </li>

          <li
            data-selected={activeSection.includes('appointment')}
            onClick={() => {
              setActiveSection('appointment')
              setCollapsed(true)
            }}
            className="focusable-main-menu"
          >
            <AppointmentIcon />
            <Text sx={styles.menuItemLabel(collapsed)}>Appointments</Text>
          </li>

          <li
            data-selected={activeSection.includes('find')}
            onClick={() => {
              setActiveSection('find')
              setCollapsed(true)
            }}
            className="focusable-main-menu"
          >
            <FindIcon />
            <Text sx={styles.menuItemLabel(collapsed)}>Find a Provider</Text>
          </li> */}

          <li
            data-selected={activeSection.includes('todo')}
            onClick={() => {
              setActiveSection('todo-pending')
              setCollapsed(true)
            }}
            className="focusable-main-menu"
          >
            <TodoIcon />
            <Text sx={styles.menuItemLabel(collapsed)}>
              {t('lg.dashboard.sideMenu.todoList')}
            </Text>
          </li>
          {/* <li
            className="focusable-main-menu"
            onClick={() => {
              setActiveSection('wellnessMenu')
              setCollapsed(true)
            }}
            data-selected={activeSection.includes('wellness')}
          >
            <WellnessIcon />
            <Text sx={styles.menuItemLabel(collapsed)}>
              {t('lg.dashboard.sideMenu.wellness')}
            </Text>
          </li>*/}
        </Flex>

        <li className="focusable-main-menu" onClick={onLogout}>
          <LogoutIcon />
          <Text sx={styles.menuItemLabel(collapsed)}>
            {t('lg.dashboard.sideMenu.logout')}
          </Text>
        </li>
      </Flex>
    </Box>
  )
}
