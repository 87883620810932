import React from 'react'
const TvContext = React.createContext<{
  tvAppMode: boolean
  wellnessCheckInTodoId: string
  setWellnessCheckInTodoId: any
  session: string
}>({
  tvAppMode: false,
  wellnessCheckInTodoId: '',
  setWellnessCheckInTodoId: () => {},
  session: ''
})
export default TvContext
