import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { trackHandler, UneeqContext, useUneeqState } from 'uneeq-react-core'
import { Button, Text } from 'rebass'

const TranscriptButton = () => {
  const { t } = useTranslation()
  const { transcriptOpen } = useUneeqState()
  const { dispatch } = useContext(UneeqContext)

  const openTranscript = () => {
    dispatch({ type: 'openTranscript', payload: true })
  }

  const closeTranscript = () => dispatch({ type: 'closeTranscript' })

  const handleClick = () => {
    transcriptOpen
      ? closeTranscript()
      : trackHandler(openTranscript, 'back-btn')()
  }

  return (
    <Button
      id="transcript-toggle"
      variant="whiteInverted"
      onClick={handleClick}
    >
      <span
        style={{
          marginRight: '8px'
        }}
      >
        &bull;
      </span>{' '}
      <Text>
        {t(
          transcriptOpen
            ? 'Question.hideTranscriptButton'
            : 'Question.showTranscriptButton'
        )}
      </Text>
    </Button>
  )
}

export default TranscriptButton
