import React, { useContext } from 'react'
// @ts-ignore
import { Button, Flex, Heading, Text } from 'rebass'
import styles from './styles'
import {
  trackHandler,
  useUneeqState,
  UneeqContext,
  useDisableBodyOverflow
} from 'uneeq-react-core'
import { MayaOverlay } from '../MayaOverlay'
import { useTranslation } from 'react-i18next'
import { useSendSocketMessage } from '../../app/hooks/useSendSocketMessage'

const MayaConfirmRestart = React.memo(() => {
  const { confirmRestartOpen } = useUneeqState()
  const sendMessage = useSendSocketMessage()

  const sendBeginMessage = () => sendMessage({ type: 'begin' })

  const { dispatch } = useContext(UneeqContext)
  const { t } = useTranslation()

  useDisableBodyOverflow(confirmRestartOpen)

  const closeConfirmRestart = () =>
    dispatch({ type: 'mayaCloseConfirmRestart' })
  const handleConfirm = () => {
    sendBeginMessage()
    closeConfirmRestart()
  }

  if (!confirmRestartOpen) return null

  return (
    <MayaOverlay>
      <Flex sx={styles.container}>
        <Heading sx={styles.heading}>{t('RestartSession.title')}</Heading>
        <Text sx={styles.text}>{t('RestartSession.subtitle')}</Text>
        <Flex sx={styles.buttonsContainer}>
          <Button
            id="confirm"
            variant="whiteInverted"
            onClick={trackHandler(handleConfirm, 'exit-session-btn')}
          >
            {t('RestartSession.confirm')}
          </Button>
          <Button
            id="cancel"
            variant="primary"
            sx={styles.backToChatButton}
            onClick={closeConfirmRestart}
          >
            {t('RestartSession.dismiss')}
          </Button>
        </Flex>
      </Flex>
    </MayaOverlay>
  )
})

export default MayaConfirmRestart
