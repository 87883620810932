import React from 'react'
import { Flex, Text, Box, Button } from 'rebass'
import { Label } from '@rebass/forms'
import { styles } from './taskListStyles'
import { ReactComponent as CheckIcon } from './check.svg'
import { Todo } from '../../types/Todo'
import formatRelative from 'date-fns/formatRelative'
import { motion, AnimatePresence } from 'framer-motion'
import { ReactComponent as ArrowRight } from '../WellnessCheck/arrow-right.svg'
import { endOfDay, startOfDay } from 'date-fns'
import { enUS } from 'date-fns/locale'
import { useApplicationState } from '../../../hooks/useApplicationState'

type TaskListProps = {
  title: string
  subtitle: string
  active: boolean
  setActive: () => void
  todoList: Todo[]
  toggleCheck: (checked: boolean, id: string) => void
  animateRight?: boolean
  setWellnessCheckInTodoId?: (id: string) => void
  onWellnessCheck: () => void
  focusableClass: string
}
export const TaskList = React.memo(
  ({
    title,
    subtitle,
    active,
    setActive,
    todoList,
    toggleCheck,
    animateRight,
    setWellnessCheckInTodoId,
    onWellnessCheck,
    focusableClass
  }: TaskListProps) => {
    const MotionLabel = motion.custom(Label)

    console.log('todoList', todoList)

    const { currentLanguage } = useApplicationState({})

    const shortLanguage = currentLanguage.includes('/')
      ? currentLanguage.split('/')[0]
      : currentLanguage

    const getTodoTitle = (todo: Todo) => {
      const parameterTodoSpanishTranslations = {
        'Check your weight.': 'Revise peso.',
        'Check your pulse.': 'Revise ritmo cardíaco.',
        'Check your oxygen saturation.': 'Revise saturación de oxígeno.',
        'Check your blood pressure.': 'Revise presión arterial.',
        'Check your blood glucose level': 'Revise nivel de glucosa en sangre.'
      }
      if (shortLanguage === 'es') {
        if (todo.type === 'parameterTodo') {
          // @ts-ignore
          return parameterTodoSpanishTranslations[todo.title]
        }
        if (todo.type === 'medication') {
          // @ts-ignore
          return `Tome su ${todo.medicationSchedule.medicineName}`
        }
      }
      return todo.todo?.name[shortLanguage] || todo.title || todo.todo?.title
    }

    const today = new Date()

    const formatRelativeDate = (
      date: any,
      baseDate: any,
      fullDate: boolean = true
    ) => {
      let formatRelativeLocale: any = {
        lastWeek: "'last' eeee p",
        yesterday: "'yesterday' p",
        today: "'today' p",
        tomorrow: "'tomorrow' p",
        nextWeek: 'eeee p',
        other: 'P'
      }

      if (shortLanguage === 'es') {
        formatRelativeLocale = {
          lastWeek: "'el' eeee 'pasado' p",
          yesterday: "'ayer' p",
          today: "'hoy' p",
          tomorrow: "'mañana' p",
          nextWeek: 'eeee p',
          other: 'P'
        }
      }

      let formatRelativeLocaleDateOnly: any = {
        lastWeek: "'last' eeee",
        yesterday: "'yesterday'",
        today: "'today'",
        tomorrow: "'tomorrow'",
        nextWeek: 'eeee',
        other: 'P'
      }

      if (shortLanguage === 'es') {
        formatRelativeLocaleDateOnly = {
          lastWeek: "'el' eeee 'pasado'",
          yesterday: "'ayer'",
          today: "'hoy'",
          tomorrow: "'mañana'",
          nextWeek: 'eeee',
          other: 'P'
        }
      }

      const locale = {
        ...enUS,
        formatRelative: (token: any) =>
          fullDate
            ? formatRelativeLocale[token]
            : formatRelativeLocaleDateOnly[token]
      }
      return formatRelative(date, baseDate, { locale })
    }

    return (
      <Flex onClick={setActive} sx={styles.card(active)}>
        <Box sx={styles.header}>
          <Text sx={styles.title}>{title}</Text>
          <Text sx={styles.subtitle}>{subtitle}</Text>
        </Box>
        <Flex as="form" sx={styles.list}>
          <AnimatePresence>
            {todoList
              .filter(
                item =>
                  new Date(item._completeBy) >= startOfDay(today) &&
                  new Date(item._completeBy) <= endOfDay(today)
              )
              .sort((a: any, b: any) => {
                return (
                  new Date(a._completeBy).valueOf() -
                  new Date(b._completeBy).valueOf()
                )
              })
              .map((todo, idx) => (
                <MotionLabel
                  sx={styles.label}
                  key={todo._id}
                  transition={{ duration: 0.6 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0, x: animateRight ? '100%' : '-100%' }}
                >
                  {todo?.todo?.title.includes(
                    'Check In'
                  ) ? // <Box sx={styles.inputContainer}>
                  //   <Flex sx={styles.checkbox}>
                  //     <CheckIcon />
                  //   </Flex>
                  //   <Button
                  //     className="focusable-wellness-menu"
                  //     onClick={e => {
                  //       e.preventDefault()
                  //       setWellnessCheckInTodoId &&
                  //         setWellnessCheckInTodoId(todo._id)
                  //       onWellnessCheck()
                  //     }}
                  //     variant="unstyled"
                  //     sx={{ ...styles.wellnessCheckinButton }}
                  //   >
                  //     {getTodoTitle(todo)}
                  //     <ArrowRight />
                  //   </Button>
                  // </Box>
                  null : (
                    <Box sx={styles.inputContainer}>
                      <input
                        className={focusableClass}
                        type="checkbox"
                        id={todo._id}
                        name={getTodoTitle(todo)}
                        defaultChecked={todo.status === 'completed'}
                        onChange={e => toggleCheck(e.target.checked, todo._id)}
                        onFocus={setActive}
                        style={{
                          position: 'absolute',
                          opacity: 0
                        }}
                      />
                      <Flex sx={styles.checkbox}>
                        <CheckIcon />
                      </Flex>
                      <Flex sx={styles.checkboxTextContainer}>
                        <Text sx={styles.checkboxTitle}>
                          {getTodoTitle(todo)}
                        </Text>
                        <Text sx={styles.checkboxSubtitle}>
                          {formatRelativeDate(
                            new Date(todo._completeBy),
                            today,
                            todo.type === 'medication'
                          )}
                        </Text>
                      </Flex>
                    </Box>
                  )}
                </MotionLabel>
              ))}
          </AnimatePresence>
        </Flex>
      </Flex>
    )
  }
)
