import { useContext } from 'react'
import TvContext from '../contexts/TvContext'

export const useTvContext = () => {
  const tvContext = useContext(TvContext)

  return tvContext
    ? tvContext
    : {
        tvAppMode: false,
        wellnessCheckInTodoId: '',
        setWellnessCheckInTodoId: () => {},
        session: ''
      }
}
