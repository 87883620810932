const styles = {
  select: {
    outline: 'none',
    border: 'none',
    width: 'max-content',
    '& option': {
      color: 'text',
      bg: 'background'
    },
    color: 'text',
    fontSize: 0,
    lineHeight: 'body',
    appearance: 'none',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    bg: 'background',
    fontFamily: 'inherit',
    ':focus': {
      outline: 'none'
    },
    pr: 10
  },
  selectContainer: {
    zIndex: 99,
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    bg: 'backgroundPrimary',
    color: 'text',
    backdropFilter: 'blur(50px)',
    display: 'flex',
    borderRadius: 'buttonBig',
    alignItems: 'center',
    py: 1,
    px: 2
  },
  selectArrow: {
    pointerEvents: 'none',
    lineHeight: 1,
    ml: -8,
    transform: 'translateY(1px)'
  }
}

export default styles
