import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useChangeLanguage } from '../app/hooks/useChangeLanguage'
import { LanguageInformation } from '../customPlugins/LanguageSelector/LanguageInformation'
import { LanguageOption } from '../customPlugins/LanguageSelector/LanguageOption'
import { getToken } from '../socket'

type UseApplicationStateProps = { embeddedApp?: boolean }
export const useApplicationState = ({
  embeddedApp
}: UseApplicationStateProps) => {
  const { i18n } = useTranslation()
  // For faster testing, skip straight to digitalHuman
  const [headingMessage, setHeadingMessage] = useState('')
  const [logo, setLogo] = useState('')
  const [disableDH, setDisableDH] = useState(true)
  const [initError, setInitError] = useState('')
  const [session, setSession] = useState('')
  const [currentLanguage, setCurrentLanguage] = useState('')
  const [disclaimer, setDisclaimer] = useState(null)
  const [supportedLanguages, setSupportedLanguages] = useState<
    LanguageOption[]
  >([])
  const [personaId, setPersonaId] = useState(
    process.env.REACT_APP_UNEEQ_PERSONA_ID || ''
  )

  const setNewLanguage = useCallback(
    (lang: LanguageInformation) => {
      // add language bundle and overrides current one if already added
      i18n.addResourceBundle(
        transformLanguageToISO(lang.key),
        'translation',
        lang.translation,
        true,
        true
      )
      setCurrentLanguage(transformLanguageToISO(lang.key))
      setPersonaId(lang.personaId)
    },
    [i18n]
  )

  useChangeLanguage(setNewLanguage)

  useEffect(() => {
    if (currentLanguage) {
      i18n.changeLanguage(currentLanguage)
    }
  }, [currentLanguage, i18n])

  useEffect(() => {
    getToken()
      .then(
        ({
          message,
          disableDigitalHuman,
          logo,
          title,
          i18n: i18nProps,
          supportedLanguages,
          session,
          disclaimer
        }: any) => {
          const headingMessage = message || ' '
          setHeadingMessage(headingMessage)
          setDisableDH(disableDigitalHuman)
          setLogo(logo)
          if (title && !embeddedApp) {
            document.title = title
          }

          if (disclaimer) {
            setDisclaimer(disclaimer)
          }

          if (i18nProps) {
            setNewLanguage(i18nProps)
          }

          setSupportedLanguages(
            supportedLanguages.map((lang: any) => ({
              ...lang,
              code: transformLanguageToISO(lang.code)
            }))
          )

          if (session) {
            setSession(session)
          }
        }
      )
      .catch(({ message }: any) => {
        setInitError(message)
      })
  }, [embeddedApp, setNewLanguage])

  return {
    headingMessage,
    logo,
    disableDH,
    initError,
    currentLanguage,
    supportedLanguages,
    personaId,
    session,
    disclaimer
  }
}

export const transformLanguageToISO = (lang: string) => {
  if (lang && !lang.includes('-')) return lang
  const langSplit = (lang.split('-') as unknown) as string[]
  const location = langSplit[1].toUpperCase()
  return `${langSplit[0]}-${location}`
}
