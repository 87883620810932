import React, { useCallback, useState, useEffect } from 'react'
import { Box } from 'rebass'
import { ThemeProvider } from 'emotion-theming'

import theme, { THEMES } from '../theme'
import Home from '../app/Home'

import DigitalHuman from '../app/DigitalHuman'
import SessionTimedOut from '../app/SessionTimedOut'
import FatalError from '../customPlugins/MayaErrors/FatalError'
import { useApplicationState } from '../hooks/useApplicationState'
import { useAutoResize } from '../hooks/useAutoResize'
import { useScrollToTop } from '../hooks/useScrollToTop'
import { Login } from './Login'
import TvContext from '../app/contexts/TvContext'
import { MainMenu } from './MainMenu'
import { useSpatialNavigation } from './hooks/useSpatialNavigation'
import { IntroductionVideo } from './MainMenu/IntroductionVideo/IntroductionVideo'
import { StorageKeys } from './types/StorageKeys'
import { Disclaimer } from './MainMenu/Disclaimer/Disclaimer'
import { useSendSocketMessage } from '../app/hooks/useSendSocketMessage'
import { endSession } from '../socket'

const backgroundStyle = {
  label: 'wrapper',
  width: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'right bottom',
  backgroundColor: '#004C9C',
  overflow: 'hidden',
  position: 'absolute',
  top: 0,
  left: 0
}

type TvSteps =
  | 'welcome'
  | 'digitalHuman'
  | 'timed-out'
  | 'login'
  | 'mainMenu'
  | 'introductionVideo'
  | 'disclaimer'

const clearStorage = () => {
  localStorage.removeItem(StorageKeys.API_USER)
  localStorage.removeItem(StorageKeys.API_TOKEN)
}

const App = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const timeoutTestTime = Number(queryParams.get('test_timeout_time'))

  const [step, goTo] = useState<TvSteps>('login')
  const [speak, setSpeak] = useState(true)
  useSpatialNavigation()
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.keyCode === 13) {
        if (document.activeElement) {
          ;((document.activeElement as unknown) as any).click()
        }
      }
    }

    document.addEventListener('keydown', listener)

    return () => document.removeEventListener('keydown', listener)
  }, [])

  useEffect(() => {
    // @ts-ignore
    const SpatialNavigation = window.SpatialNavigation
    if (SpatialNavigation) {
      SpatialNavigation.makeFocusable()
    }
  })

  const {
    currentLanguage,
    disableDH,
    personaId,
    headingMessage,
    initError,
    logo,
    supportedLanguages,
    session,
    disclaimer
  } = useApplicationState({})

  const restart = () => goTo('mainMenu')

  const stepIsDigitalHuman = useCallback(() => step === 'digitalHuman', [step])

  const height = useAutoResize({ condition: stepIsDigitalHuman })
  useScrollToTop({ condition: stepIsDigitalHuman })

  const navigateToDigitalHuman = useCallback(() => goTo('welcome'), [])
  const navigateToVideo = useCallback(() => goTo('introductionVideo'), [])
  const navigateToMainMenu = useCallback(() => goTo('mainMenu'), [])
  const navigateToDisclaimer = useCallback(() => goTo('disclaimer'), [])

  const acceptDisclaimer = () => {
    localStorage.setItem(StorageKeys.DISCLAIMER_SHOWN, 'true')
    navigateToMainMenu()
  }

  const logout = useCallback(() => {
    clearStorage()
    goTo('login')
  }, [])

  const [wellnessCheckInTodoId, setWellnessCheckInTodoId] = useState('')

  console.log(
    'wellnessCheckInTodoId',
    wellnessCheckInTodoId,
    'session',
    session
  )

  return (
    <ThemeProvider theme={theme(THEMES.DARK, false)}>
      <TvContext.Provider
        value={{
          tvAppMode: true,
          wellnessCheckInTodoId,
          setWellnessCheckInTodoId,
          session
        }}
      >
        <Box
          sx={{
            ...backgroundStyle,
            height: height || '100%',
            overflow: step === 'welcome' ? 'visible' : 'hidden'
          }}
        >
          {initError && (
            <FatalError
              errorTitle="Something went wrong"
              errorMessage={initError}
              clearError={window.location.reload}
              buttonText="Try again"
            />
          )}
          {step === 'login' ? (
            <Login onSuccess={navigateToVideo} />
          ) : step === 'digitalHuman' ? (
            <DigitalHuman
              speak={speak}
              onTimedOut={() => goTo('timed-out')}
              restart={restart}
              embeddedMode={false}
              onSessionEnded={() => {
                restart()
                endSession()
              }}
              personaId={personaId}
              testTimeoutTime={timeoutTestTime}
            />
          ) : step === 'timed-out' ? (
            <SessionTimedOut restart={restart} />
          ) : step === 'mainMenu' ? (
            <MainMenu
              onWellnessCheck={navigateToDigitalHuman}
              setWellnessCheckInTodoId={setWellnessCheckInTodoId}
              onLogout={logout}
            />
          ) : step === 'introductionVideo' ? (
            <IntroductionVideo onFinishVideo={navigateToDisclaimer} />
          ) : step === 'disclaimer' ? (
            <Disclaimer
              handleAccept={acceptDisclaimer}
              handleDecline={() => console.log('DECLINED')}
              disclaimerInfo={disclaimer}
            />
          ) : (
            <Home
              embeddedMode={false}
              startSession={speak => {
                setSpeak(speak)
                goTo('digitalHuman')
              }}
              restart={() => goTo('welcome')}
              headingMessage={headingMessage}
              disableDigitalHuman={disableDH}
              logo={logo}
              languageSelected={currentLanguage}
              supportedLanguages={supportedLanguages}
            />
          )}
        </Box>
      </TvContext.Provider>
    </ThemeProvider>
  )
}

export default App
