import { useEffect } from 'react'
import { useUneeqState } from 'uneeq-react-core'
import { useSendSocketMessage } from '../../app/hooks/useSendSocketMessage'
import useMayaConnect from './useMayaConnect'

const MayaUi = ({ speak }) => {
  useMayaConnect()
  const sendMessage = useSendSocketMessage()
  const { ready } = useUneeqState()

  useEffect(() => {
    if (ready) sendMessage({ type: 'begin', speak })
  }, [speak, ready, sendMessage])

  if (!ready) return null
  // <Component onClick={() => sendMessage('do something')} />
  // <Component sendRequest={sendMessage} />
  return null
}

export default MayaUi
